import React from 'react'
import { getServerSideProps } from '../lib/misc/get-server-side-props'
import { ProjectsPage } from '../modules/projects/projects-page'
import { PageWrapper } from '../modules/page-wrapper'

const page: React.FC = () => {
  return (
    <PageWrapper>
      <ProjectsPage />
    </PageWrapper>
  )
}

export default page

export { getServerSideProps }
